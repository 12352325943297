module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/builds/psygig/docs/node_modules/gatsby-remark-images","id":"7f875840-f501-5ed3-9dd5-4150353f494d","name":"gatsby-remark-images","version":"3.2.1","pluginOptions":{"plugins":[],"maxWidth":1500,"withWebp":true,"backgroundColor":"#496D8F","linkImagesToOriginal":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-smartypants","options":{}},{"resolve":"gatsby-remark-slug","options":{}},{"resolve":"gatsby-remark-copy-linked-files","options":{}},{"resolve":"gatsby-remark-embed-snippet","options":{"directory":"/builds/psygig/docs/docs/"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1500,"withWebp":true,"backgroundColor":"#496D8F","linkImagesToOriginal":true}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{}}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500,"withWebp":true,"backgroundColor":"#496D8F","linkImagesToOriginal":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
